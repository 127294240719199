<template>
    <div class="product width">
        <div class="product_title">商业研究</div>
        <div class="product_list">
            <div class="product_item" v-for="(item, index) in list" :key="index" :class="{active: item.placeType == 2}">
                <div class="product_logo clearfix">
                    <img :src="item.imageUrl" :alt="item.focusTitle">
                </div>
                <div class="product_desc">
                    <div class="product_title">{{item.focusTitle}}</div>
                    <div class="product_desc_small">{{item.content}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }
    },
    data() {
        return {
        }
    }
}
</script>
<style lang='scss' scoped>
.product {
    background-color: #FFFFFF;
    padding: 40px 0;
}
.product_title {
    font-size: 36px;
    color: #2D2D2D;
    line-height: 54px;
}
.product_list {
    margin-top: 40px;
    min-height: 301px;
}
.product_item {
    background: #EBEFF5;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    &.active {
        flex-direction: row-reverse;
        .product_logo {
            margin-right: 0;
            margin-left: 36px;
        }
    }
    .product_logo {
        width: 554px;
        height: 261px;
        margin-right: 36px;
        overflow: hidden;
        cursor: pointer;
        border-radius: 16px;
        img {
            width: 100%;
            height: 100%;
            float: left;
            transition: all 0.5s;
        }
        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }
    .product_title {
        font-size: 24px;
        color: #2D2D2D;
        line-height: 33px;
        margin-top: 14px;
        margin-bottom: 38px;
    }
    .product_desc {
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        flex: 1;
    }
    & + .product_item {
        margin-top: 20px;
    }
}
@media screen and (min-width: 320px) and (max-width: 750px){
    .product {
        padding: 0.4rem 0;
    }
    .product_title {
        font-size: 0.36rem;
        line-height: 0.54rem;
    }
    .product_list {
        margin-top: 0.4rem;
        min-height: 301px;
    }
    .product_item {
        background: #EBEFF5;
        border-radius: 0.1rem;
        padding: 0.2rem;
        display: block;
         &.active {
            .product_logo {
                margin-left: 0;
            }
        }
        .product_logo {
            width: 100%;
            height: 3.2rem;
            margin-right: 0;
            overflow: hidden;
            border-radius: 0.16rem;
            img {
                width: 100%;
                height: 100%;
                float: left;
                transition: none;
            }
            &:hover {
                img {
                    transform: scale(1);
                }
            }
        }
        .product_title {
            font-size: 0.30rem;
            line-height: 0.32rem;
            margin: 0.22rem 0;
        }
        .product_desc {
            font-size: 0.22rem;
            line-height: 0.3rem;
        }
        & + .product_item {
            margin-top: 0.2rem;
        }
    }
}
</style>