<template>
  <div class="home" v-cloak>
    <div class="floor"></div>
    <Banner :list='bannerFocusList'/>
    <div class="floor"></div>
    <Case :listArr='caseFocusList'/>
    <div class="floor"></div>
    <Product :list='productFocusList'/>
     <Founder/>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue'
import Case from '@/components/Case.vue'
import Product from '@/components/Product.vue'
import Founder from '@/components/Founder.vue'
import axios from 'axios'
export default {
  name: 'Home',
  components: {
    Banner,
    Case,
    Product,
    Founder
  },
  data() {
    const bannerFocusList = window.localStorage.getItem('bannerFocusList') || ''
    const caseFocusList = window.localStorage.getItem('caseFocusList') || ''
    const productFocusList = window.localStorage.getItem('productFocusList') || ''
    return {
      bannerFocusList: bannerFocusList ? JSON.parse(bannerFocusList) : [],
      caseFocusList: caseFocusList ? JSON.parse(caseFocusList) : [],
      productFocusList: productFocusList ? JSON.parse(productFocusList) : [],
    }
  },
  created() {
    axios.get('/web/focus/getBookFocus.do')
    .then((res) => {
      const result = res.data.result || {}
      console.log(result)
      const { bannerFocusList, caseFocusList, productFocusList } = result
      if (bannerFocusList) {
        bannerFocusList.forEach(item => {
          item.imageUrl = item.imagePathList.join(',')
          let html = document.documentElement;
          let wW = html.clientWidth;// 窗口宽度
          if (wW <= 750) {
              item.imageUrl = item.bigImagePathList.join(',')
          }
        })
        window.localStorage.setItem('bannerFocusList', JSON.stringify(bannerFocusList))
        this.bannerFocusList = bannerFocusList
      }
      if (caseFocusList) {
        caseFocusList.forEach(item => {
          item.imageUrl = item.imagePathList.join(',')
        })
        window.localStorage.setItem('caseFocusList', JSON.stringify(caseFocusList))
        this.caseFocusList = caseFocusList
      }
      if (productFocusList) {
        productFocusList.forEach(item => {
          item.imageUrl = item.imagePathList.join(',')
        })
        window.localStorage.setItem('productFocusList', JSON.stringify(productFocusList))
        this.productFocusList = productFocusList
      }
    })
    .catch((err) => {
      console.log(err);
    })
  },
  mounted() {
    window.addEventListener('scroll', this.navScroll, true)
  },
  methods: {
    navScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let floor = document.querySelectorAll('.floor')
      let navNum = 0
      floor.forEach((item, index) => {
        if (scrollTop >= item.offsetTop - 200) {
          navNum = index
        }
      })
      this.$store.dispatch('setNavNum', navNum)
    }
  }
}
</script>
<style scoped>
[v-cloak] {
     display: none;
}
.floor {
  transform: translateY(-90px);
}
</style>
