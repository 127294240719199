<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <div class="nav">
      <div class="width">
        <div class="nav_logo">
          <img src="./assets/logo.png" alt="logo">
        </div>
        <ul class="nav_list">
          <li class="nav_item"
            @click="changeNavNum(index)"
            :class="{active: navNum === index}"
            v-for="(item, index) in navList"
            :key="index">{{item}}</li>
        </ul>
        <img class="tip" src="./assets/nav_btn.png" @click="navShow = !navShow">
        <ul class="nav_list_m" v-show="navShow">
          <li class="nav_item_m"
            @click="changeNavNum(index)"
            :class="{active: navNum === index}"
            v-for="(item, index) in navList"
            :key="index">{{item}}</li>
        </ul>
      </div>
    </div>
    <div class="main">
      <router-view/>
    </div>
    <footer class="footer">
      <div class="width">
        <div class="code_list">
          <div class="code_item">
            <img src="./assets/code.png" alt="公众号">
            扫码关注公众号
          </div>
          <div class="code_item">
            <img src="./assets/code_02.png" alt="公众号">
            扫码收听播客
          </div>
          <div class="code_item">
            <img src="./assets/code_01.png" alt="公众号">
            关注场景实验室微博
          </div>
        </div>
        <div class="nav_logo">
          <img src="./assets/logo_02.png" alt="logo">
        </div>
        <div class="footer_desc">
          <div class="footer_label">
            <div class="footer_label_item">
              <div class="footer_label_desc">
                <div>商务合作：</div>
                <div>
                  <a href="mailto:zhangcheng@context.cn?subject=&body=" rel="nofollow">zhangcheng@context.cn</a>
                </div>
              </div>
              <div class="footer_label_desc">
                <div>品牌合作：</div>
                <div><a href="mailto:xuqian@context.cn?subject=&body=" rel="nofollow">xuqian@context.cn</a></div>
              </div>
              <div class="footer_label_desc">
                <div>联系电话：</div>
                <div>010-8459 9793</div>
              </div>
            </div>
            <div class="footer_label_item">
              <div class="footer_label_desc f_box">
                <div>公司地址：</div>
                <div>北京：北京市朝阳区酒仙桥4号</div>
                <div class="pl">751D·PARK北京时尚设计广场A21栋，场景实验室</div>
                <div class="mt">上海：上海市静安区胶州路273弄60号</div>
                <div class="pl">现所305室，场景实验室</div>
                <div class="mt">场景实验室是场景智造（北京）科技有限公司旗下品牌</div>
              </div>
              <div class="footer_label_desc f_box_01">
                <div>公司地址：</div>
                <div>北京：北京市朝阳区酒仙桥4号</div>
                <div class="pl">751D·PARK北京时尚设计广场A21栋，场景实验室</div>
                <div class="mt">上海：上海市静安区胶州路273弄60号</div>
                <div class="pl">现所305室，场景实验室</div>
                <div class="mt">场景实验室是场景智造（北京）科技有限公司旗下品牌</div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer_icon">
          <div class="footer_icon_item">
            <img src="./assets/wechat.png" alt="微信">
            <div class="footer_code">
              <img src="./assets/code.png" alt="公众号">
              <div>扫码关注公众号</div>
            </div>
          </div>
          <div class="footer_icon_item">
            <img src="./assets/boke.png" alt="微信">
            <div class="footer_code">
              <img src="./assets/code_02.png" alt="公众号">
              <div>扫码收听播客</div>
            </div>
          </div>
          <div class="footer_icon_item">
            <img src="./assets/weibo.png" @click="target(weiBoHref)" alt="微博">
          </div>
        </div>
      </div>
      <div class="record">场景智造（北京）科技有限公司版权所有 | <span @click="target('https://beian.miit.gov.cn/')">京ICP备2020036248号-1</span></div>
    </footer>
  </div>
</template>
<script>
export default {
  computed: {
    navNum() {
      return this.$store.state.navNum
    }
  },
  data() {
    return {
      weiBoHref: 'https://www.weibo.com/u/6289256476?topnav=1&wvr=6&topsug=1&is_hot=1',
      navList: ['公司简介', '服务案例', '商业研究'],
      navShow: false
    }
  },
  methods: {
    // 跳转微博
    target(href) {
      window.open(href)
    },
    changeNavNum(index){
      this.$store.dispatch('setNavNum', index)
      const floor = document.querySelectorAll('.floor')
      floor[index].scrollIntoView({ behavior: "smooth" })
    }
  }
}
</script>
<style lang="scss">
html {
  width: 100%;
}
#app {
  font-family: '微软雅黑', PingFangSC-Regular, PingFang SC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2D2D2D;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.width {
  width: 1180px;
  margin: 0 auto;
}
.clearfix{
  &::after {
      content:"";
      display:block;
      visibility:hidden;
      clear:both;
  }
}
.main {
  padding-top: 90px;
}
.nav {
  padding: 16px 0;
  background-color: #fff;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(29, 29, 29, 0.25);;
  .width {
    display: flex;
  }
  .nav_logo {
    width: 178px;
    height: 58px;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tip {
    display: none;
  }
  .nav_list {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    color: #666666;
    line-height: 58px;
    // text-shadow: 0px 2px 4px rgba(29, 29, 29, 0.25);
    .nav_item {
      margin-left: 16px;
      cursor: pointer;
      &:hover {
        color: #1D1D1D;
      }
    }
    .active {
      color: #1D1D1D;
    }
  }
}
.footer {
  padding: 28px 0 24px;
  background-color: #fff;
  .width {
    display: flex;
  }
  .nav_logo {
    width: 159px;
    height: 40px;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .footer_desc {
    flex: 1;
    font-size: 14px;
    color: #2D2D2D;
    line-height: 22px;
    padding-left: 83px;
    a {
      color: #2D2D2D;
      text-decoration: none;
    }
  }
  .footer_icon {
    display: flex;
  }
  .footer_label {
    display: flex;
  }
  .footer_label_item + .footer_label_item {
    margin-left: 104px;
  }
  .f_box_01 {
    display: none;
  }
  .footer_label_desc {
    cursor: pointer;
    & + .footer_label_desc {
      margin-top: 20px;
    }
    .mt {
      margin-top: 20px;
    }
    .pl {
      padding-left: 44px;
    }
  }
  .footer_icon_item {
    width: 36px;
    height: 31px;
    cursor: pointer;
    position: relative;
    padding-top: 30px;
    margin-top: -30px;
    > img {
      width: 100%;
      height: 100%;
    }
    &:last-child {
      width: 37px;
      height: 29px;
    }
    & + .footer_icon_item {
      margin-left: 49px;
    }
    &:hover {
      .footer_code {
        opacity: 100;
      }
    }
  }
  .record {
    text-align: center;
    font-size: 13px;
    color: #666666;
    line-height: 22px;
    margin-top: 30px;
    span {
      cursor: pointer;
    }
  }
  .footer_code {
    opacity: 0;
    position: absolute;
    padding: 8px 8px 0;
    background-color: #fff;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.37);
    border-radius: 8px;
    font-size: 12px;
    color: #666666;
    line-height: 17px;
    text-align: center;
    top: -95px;
    left: 50%;
    transform: translateX(-50%);
    > img {
      width: 90px;
      height: 90px;
      display: block;
    }
  }
}
.code_list {
  display: none;
}
@media screen and (min-width: 320px) and (max-width: 750px){
  .over {
  overflow: hidden;
}
  .main {
    padding-top: 0.84rem;
  }
  .nav {
    padding: 0.13rem 0;
    .width {
      justify-content: space-between;
    }
    .nav_logo {
      width: 1.78rem;
      height: 0.58rem;
    }
    .nav_list {
      display: none;
    }
    .nav_list_m {
      position: absolute;
      width: 2.07rem;
      height: 2.9rem;
      background: #FFFFFF;
      box-shadow: 0px 0.02rem 0.04rem 0px rgba(29,29,29,0.25);
      border: 1px solid #CBCBCB;
      padding-top: 0.42rem;
      text-align: center;
      box-sizing: border-box;
      color: #666666;
      line-height: 0.32rem;
      font-size: 0.24rem;
      right: 0.32rem;
      bottom: 0;
      transform: translateY(100%);
      border: 1px solid #CBCBCB;
      border-radius: 0.06rem;
      .nav_item_m + .nav_item_m {
        margin-top: 0.47rem;
      }
      .active {
        color: #1D1D1D;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: 0.13rem solid #000;
        border-color: transparent transparent rgba(29,29,29,0.25) transparent;
        top: -0.28rem;
        right: 0.05rem;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: 0.13rem solid #000;
        border-color: transparent transparent #fff transparent;
        top: -0.28rem;
        transform: translateY(1px);
        right: 0.05rem;
      }
    }
    .tip {
      display: block;
      width: 0.5rem;
      height: 0.3rem;
      margin-right: 0.08rem;
      margin-top: 0.14rem;
    }
  }
  .width {
    width: 7.14rem;
  }
  .footer {
  padding: 28px 0 24px;
  background-color: #fff;
  font-size: 0.22rem;
  line-height: 0.32rem;
  .width {
    display: block;
  }
  .nav_logo {
    display: none;
  }
  .footer_desc {
    font-size: 0.22rem;
    line-height: 0.32rem;
    padding-left: 0;
    a {
      color: #2D2D2D;
      text-decoration: none;
    }
  }
  .footer_icon {
    display: none;
  }
  .footer_label_item {
    display: block;
  }
  .f_box {
    display: none !important;
  }
  .f_box_01 {
    display: block;
  }
  .footer_label {
    display: block;
  }
  .footer_label_desc {
    cursor: pointer;
  }
  
  .footer_label_item + .footer_label_item {
    margin-left: 0;
    .footer_label_desc + .footer_label_desc{
      margin-left: 0;
      display: block;
      > div {
        display: block;
        &.pl {
          margin-left: 0.68rem;
        }
      }
    }
  }
  .footer_label_desc {
    cursor: pointer;
    display: flex;
    word-break: normal;
    flex-wrap: wrap;
    > div {
      display: inline;
    }
    .f_box {
      width: 100%;
    }
    & + .footer_label_desc {
      margin-top: 0;
    }
    .mt {
      margin-top: 0;
    }
    .pl {
      padding-left: 0;
    }
  }
  .record {
    text-align: left;
    font-size: 0.22rem;
    line-height: 0.32rem;
    width: 7.14rem;
    margin: 0.18rem auto 0;
  }
  .footer_code {
    opacity: 0;
    position: absolute;
    padding: 8px 8px 0;
    background-color: #fff;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.37);
    border-radius: 8px;
    font-size: 12px;
    color: #666666;
    line-height: 17px;
    text-align: center;
    top: -95px;
    left: 50%;
    transform: translateX(-50%);
    > img {
      width: 90px;
      height: 90px;
      display: block;
    }
  }
}
.code_list {
  display: flex;
  margin-bottom: 0.47rem;
  color: #666666;
  line-height: 0.32rem;
  .code_item {
    & + .code_item {
      margin-left: 0.33rem;
    }
    img {
      display: block;
      margin: 0 auto;
      width: 0.82rem;
      height: 0.82rem;
    }
  }
}
}
</style>
