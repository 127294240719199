<template>
    <div class="case">
        <div class="width">
            <div class="case_title">服务案例</div>
            <div class="case_small_title">场景实验室长期为中国互联网及新经济企业提供「品牌战略咨询服务」，是国内极具特色的「品牌战略IP」解决方案提供商。</div>
            <ul class="case_list">
                <li class="case_item" @click="showItem(index)" v-for="(item, index) in list" :key="index">
                    <div class="case_inner clearfix">
                        <img class="case_img" :src="item.imageUrl" :alt="item.title">
                        <div class="case_mark">
                            <div class="case_mark_inner">
                                <div class="case_small_title">{{item.focusTitle}}</div>
                                <div class="case_desc">{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="case_btn" v-if="listArr && listArr.length > maxLength">
                <div class="case_btn_item" @click="changeList" v-show="!isMore">展开查看更多<img src="../assets/more_01.png"></div>
                <div class="case_btn_item" @click="changeList" v-show="isMore">收起<img src="../assets/more_02.png"></div>
            </div>
        </div>
        <div class="case_mark_box" v-if="isShow">
            <div class="case_inner">
                <img class="case_img" :src="item.imageUrl" :alt="item.title">
                <div class="case_mark_title">{{item.focusTitle}}</div>
                <div class="case_mark_desc">{{item.content}}</div>
                <img class="close" @click="close" src="../assets/close.png" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        listArr: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            maxLength: 12,
            list: [],
            isMore: false,
            item: {"focusTitle":"腾讯CSIG","focusType":1,"imagePathList":["https://image.context.cn/d441dd8ac9b841a8a08a49af8cbe8015.png"],"bigImagePathList":null,"linkUrl":"www.baidu.com","placeType":0,"sortNum":0,"content":"场景实验室连续3年为腾讯CSIG提供品牌战略咨询服务，打造腾讯智慧零售「超级连接」、腾讯云「场景云」、腾讯智慧出行「超级流畅」品牌战略IP，并完成战略发布会议题设计、品牌战略专著策划、品牌话语体系设计等服务内容，助力腾讯CSIG产业互联网战略升级。","imageUrl":"https://image.context.cn/d441dd8ac9b841a8a08a49af8cbe8015.png"},
            isShow: false
        }
    },
    watch: {
        listArr() {
            this.computedLength()
        }
    },
    created() {
        this.computedLength()
    },
    methods: {
        computedLength() {
            const { listArr, maxLength } = this
            if (listArr.length > maxLength) {
                this.list = listArr.slice(0, maxLength)
            } else {
                this.list = listArr
            }
        },
        changeList() {
            const { isMore, listArr, maxLength } = this
            if (!isMore) {
                this.list = listArr
            } else {
                 this.list = listArr.slice(0, maxLength)
            }
            this.isMore = !isMore
        },
        showItem(index) {
            this.item = this.list[index]
            this.isShow = true
            var bodyEle = document.body
            bodyEle.classList.add('over')
        },
        close() {
            this.isShow = false
            var bodyEle = document.body
            bodyEle.classList.remove('over')
        }
    }
}
</script>
<style lang='scss' scoped>
.case_btn {
    text-align: center;
    font-size: 14px;
    color: #002FA7;
    line-height: 20px;
    padding: 40px 0;
    .case_btn_item {
        display: inline-block;
        cursor: pointer;
        img {
            width: 10px;
            height: 6px;
            margin-left: 8px;
            vertical-align: top;
            margin-top: 7px;
        }
    }
}
.case {
    padding: 40px 0;
    background-color: #EBEFF5;
    color: #2D2D2D;
}
.case_title {
    font-size: 36px;
    color: #2D2D2D;
    line-height: 54px;
}
.case_small_title {
    font-size: 16px;
    line-height: 24px;
}
.case_list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    min-height: 208px;
    .case_item {
        width: 380px;
        height: 188px;
        margin-bottom: 20px;
        margin-right: 20px;
        cursor: pointer;
        &:nth-child(3n + 3) {
            margin-right: 0;
        }
    }
    .case_inner {
        position: relative;
        overflow: hidden;
        animation: mymove 1.5s;
        &:hover {
            .case_mark {
                height: 100%;
            }
        }
    }
    .case_mark {
        height: 0;
        overflow: hidden;
        transition: all 0.8s;
        position: absolute;
        width: 380px;
        background: rgba(45, 45, 45, 0.9);
        top: 0;
        font-size: 14px;
        color: #CBCBCB;
        line-height: 20px;
        overflow: hidden;
        .case_mark_inner {
            margin: 16px 20px;
            height: 156px;
            overflow: hidden;
            box-sizing: border-box;
        }
        .case_small_title {
           line-height: 22px;
           font-size: 16px;
           margin-bottom: 14px;
        }
        .case_desc {
            max-height: 120px; 
            overflow: hidden;         
            text-overflow: ellipsis;
            text-align: justify;         
            display: -webkit-box;          
            -webkit-line-clamp: 6;            
            -webkit-box-orient: vertical;
        }
    }
    .case_img {
        width: 380px;
        height: 188px;
        float: left;
    }
}
.case_mark_box {
    display: none;
}
@media screen and (min-width: 320px) and (max-width: 750px){
    .case_mark_box {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0,0.35);
        .case_inner {
            background-color: #fff;
            padding: 0.46rem 0.42rem 0.8rem;
            position: absolute;
            width: 5.48rem;
            box-sizing: border-box;
            border-radius: 0.08rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .case_img {
            height: 2.35rem;
            display: block;
        }
        .case_mark_title {
            font-size: 0.32rem;
            line-height: 0.5rem;
            color: #2D2D2D;
            margin: 0 0 0.18rem;
        }
        .case_mark_desc {
            font-size: 0.28rem;
            line-height: 0.32rem;
            text-align: justify;    
        }
        .close {
            position: absolute;
            right: 0.2rem;
            top: 0.2rem;
            width: 0.36rem;
            height: 0.36rem;
        }
    }
    .case_btn {
        font-size: 0.14rem;
        padding: 0.4rem 0;
        line-height: 0.2rem;
        .case_btn_item {
            img {
                width: 0.1rem;
                height: 0.06rem;
                margin-left: 0.08rem;
                vertical-align: top;
                margin-top: 0.07rem;
            }
        }
    }
    .case {
        padding: 0.4rem 0;
    }
    .case_title {
        font-size: 0.36rem;
        line-height: 0.54rem;
    }
    .case_small_title {
        font-size: 0.22rem;
        line-height: 0.32rem;
    }
    .case_list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0.40rem;
        min-height: 3.46rem;
        justify-content: space-between;
        .case_item {
            width: 3.48rem;
            height: auto;
            margin-bottom: 0.2rem;
            margin-right: 0px;
            padding: 0.2rem;
            background: #FFFFFF;
            box-sizing: border-box;
            box-shadow: 1px 1px 4px 0px rgba(29,29,29,0.4);
        }
        .case_inner {
            position: relative;
            overflow: hidden;
            animation: none;
        }
        .case_mark {
            position: static;
            color: #666;
            width: 100%;
            height: auto;
            background-color: transparent;
            .case_mark_inner {
                margin: 0;
                height: auto;
            }
            .case_small_title {
                font-size: 0.24rem;
                line-height: 0.4rem;
                color: #2D2D2D;
                margin: 0 0 0.18rem;
            }
            .case_desc {
                font-size: 0.20rem;
                line-height: 0.26rem;
                 max-height: 0.52rem;        
                -webkit-line-clamp: 2;          
            }
        }
        .case_img {
            width: 3.1rem;
            height: 1.54rem;
            float: left;
        }
    }
}
@keyframes mymove {
  0% {
      width: 0;
      height: 0;
  }
  100% {
      width: 100%;
      height: 100%;
  }
}
</style>