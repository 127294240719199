<template>
    <div class="founder">
        <div class="width">
            <div class="founder_title">创始人</div>
            <div class="founder_item">
                <div class="founder_img clearfix">
                    <img src="../assets/founder_01.png" alt="创始人">
                </div>
                <div class="founder_desc">
                    <div class="founder_tit">吴&nbsp;&nbsp;声</div>
                    <div class="founder_small_tit">场景实验室创始人</div>
                    <div class="founder_small_tit">场景方法论提出者、新物种实验计划发起人</div>
                    <div class="founder_desc_item">互联网原创商业方法重要研究者。著有《场景革命：重构人与商业的连接》《超级IP：互联网新物种方法论》《新物种爆炸：认知升级时代的新商业思维》《场景纪元：从数字到场景的新商业进化》等多部商业作品，对中国新经济企业影响深远。</div>
                    <div class="founder_desc_item">吴声先生亦是罗辑思维联合创始人、吴晓波频道战略顾问、京东集团终身顾问。历任凡客诚品副总裁、京东商城高级副总裁、乐蜂网总顾问。同时受聘为长江商学院、北京大学新媒体研究院、哥伦比亚大学等机构客座教授、导师、访问学者。</div>
                    <div class="founder_desc_item">作为互联网战略资深实践者，吴声先生策划凡客体、京东815价格战、新匠人新国货运动、《时间的朋友》跨年演讲、新市井商业等一系列影响力事件。</div>
                    <div class="founder_desc_item">吴声先生受邀出任商务部中国电子商务委员会执行秘书长、APEC中国工商理事会数字经济委员会资深专家、宁波市人民政府金融专家委员会委员、厦门国际时尚周轮值主席、上海嘉定工业区产业发展顾问、深圳市罗湖区产业发展顾问等职。</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    }
}
</script>
<style lang='scss' scoped>
.founder {
    padding: 40px 0;
    background-color: #EBEFF5;
}
.founder_title {
    font-size: 36px;
    color: #2D2D2D;
    line-height: 54px;
    margin-bottom: 40px;
}
.founder_item {
    display: flex;
    padding: 29px 20px;
    background-color: #fff;
    border-radius: 10px;
    .founder_img {
        width: 554px;
        height: 379px;
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            float: left;
            transition: transform 0.8s;
        }
        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }
    .founder_desc {
        flex: 1;
        margin-left: 36px;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        .founder_tit {
            font-size: 24px;
            color: #2D2D2D;
            line-height: 33px;
            margin-bottom: 8px;
        }
        .founder_small_tit {
            color: #2D2D2D;
        }
        .founder_desc_item {
            margin-top: 18px;
        }
    }
}
@media screen and (min-width: 320px) and (max-width: 750px){
    .founder {
        padding: 0.4rem 0;
    }
    .founder_title {
        font-size: 0.36rem;
        line-height: 0.54rem;
        margin-bottom: 0.4rem;
    }
    .founder_item {
        display: block;
        padding: 0.29rem 0.2rem;
        background-color: #fff;
        border-radius: 10px;
        .founder_img {
            width: 100%;
            height: 4.63rem;
            overflow: hidden;
            border-radius: 0.1rem;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                float: left;
                transition: none;
            }
            &:hover {
                img {
                    transform: scale(1);
                }
            }
        }
        .founder_desc {
            flex: 1;
            margin-left: 0;
            font-size: 0.22rem;
            line-height: 0.32rem;
            .founder_tit {
                font-size: 0.30rem;
                line-height: 0.33rem;
                margin: 0.22rem 0;
            }
            .founder_desc_item {
                margin-top: 0.18rem;
            }
        }
    }
}
</style>