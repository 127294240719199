<template>
    <div class="banner">
        <el-carousel v-if="list && list.length > 1" :height="bannerHeight" autoplay :interval='intervalTime'  arrow='never' trigger='click'>
            <el-carousel-item v-for="(item, index) in list" :key="index">
                <div class="banner_item" :style="{'background-image': 'url(' + item.imageUrl + ')'}">
                    <div class="banner_inner">
                        <div class="banner_title">{{item.focusTitle}}</div>
                        <div class="banner_desc" v-html="list[0].content"></div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="banner_box">
            <div v-if="list && list.length == 1" class="banner_item" :style="{'background-image': 'url(' + list[0].imageUrl + ')', height: bannerHeight}">
            </div>
            <div class="banner_inner">
                <div class="banner_title">{{list[0].focusTitle}}</div>
                <div class="banner_desc" v-html="list[0].content"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            intervalTime: 5000,
            bannerHeight: '604px'
        }
    },
    created() {
        let html = document.documentElement;
        let wW = html.clientWidth;// 窗口宽度
        if (wW <= 750) {
            this.bannerHeight = '4rem'
        }
    }
}
</script>
<style lang="scss" scoped>
.banner {
    padding-bottom: 48px;
    background-color: #F6F6F6;
    height: 604px;
}
.banner_item {
    height: 100%;
    background-size: center center;
    background-repeat: no-repeat;
    background-position: center;
}
.banner_box{
    position: relative;
}
.banner_inner {
    padding-top: 178px;
    height: 100%;
    overflow: hidden;
    width: 864px;
    margin: 0 auto;
    color: #FFFFFF;
    font-weight: 400;
    animation: mymove 1.5s;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.banner_title {
    font-size: 38px;
    line-height: 53px;
}
.banner_desc {
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
}
@media screen and (min-width: 320px) and (max-width: 750px){
    .banner {
        padding-bottom: 0.48rem;
        height: auto;
    }
    .banner_item {
        background-size: 100% 100%;
    }
    .banner_inner {
        width: 100%;
        padding: 0.4rem 0.18rem 0;
        animation: none;
        box-sizing: border-box;
        position: static;
        color: #2D2D2D;
        transform: translateX(0);
    }
    .banner_title {
        font-size: 0.36rem;
        line-height: 0.45rem;
    }
    .banner_desc {
        font-size: 0.22rem;
        line-height: 0.32rem;
    }
}
@keyframes mymove {
  0% {
      padding-top: 228px;
      opacity: 0;
  }
  100% {
      padding-top: 178px;
      opacity: 1;
  }
}
</style>